.dropleft-order {
    display: flex;
    position: relative;
}

.dropleft-order > button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #444444;
    font-size: 18px;
    height: 25px;
    width: 35px;
    margin-left: -5px;
    background-color: #f3f3f3;
    border: 0.5px solid;
    border-radius: 4px;
} .dropleft-order > button:hover {
    background-color: #e8e8e8;
}

.dropleft-order > button:first-child {
    margin-right: 15px;
}

.color-refused {
    border-color: #EC5F5A !important;
    color: #EC5F5A !important;
}

.color-approved {
    border-color: #21C230 !important;
    color: #21C230 !important;
}

