.header-actions {
    display: flex;
    width: 100%;
    align-items: center;
}

.button-page-header {
    border: 2px solid #EEEEEE;
    z-index: 0px;
    margin-bottom: -6px;
    padding: 10px 15px 13px;
    border-radius: 15px 15px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    background-color: #EEEEEE;
    color: #545454;
}.button-page-header:hover {
    background-color: #e2e2e2;
    border-color:#e2e2e2;
}

.form-insert-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 40px;
    background-color: #FFFFFF;
    border: 2px solid #EEEEEE;
}

.div-input {
    width: 100%;
    margin-right: 10px;
}

.div-input > div {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.div-input > div > div {
    width: 100%;
}

.div-input > div > :first-child {
    width: 100%;
    margin-right: 10px;
}

.div-input > div > :nth-child(3) {
    margin-left: 10px;
}

.content-insert-product {
    width: 100%;
    display: flex;
}

.content-insert-product > :last-child {
    margin-top: 65px;
}

.form-insert-product label {
    font-size: 14px;
    font-weight: 600;
    color: #545454;
    padding: 5px;
}

.form-insert-product h1 {
    font-size: 20px;
    font-weight: 600;
    color: #545454;
    margin-bottom: 20px;
}

.toggles {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #CDCDCD;
    height: 40px;
    padding: 0px 10px;
    border-radius: 8px;
}

.toggles > p {
    font-weight: 600;
    color: #747474;
    margin-right: 10px;
}

.toggles:last-child {
    min-width: max-content;
}

.input-insert-product {
    padding: 14px 10px;
    width: 100%;
    height: 40px;
    border-style: solid;
    color: #CDCDCD;
    border: 2px solid #CDCDCD;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    transition: 1s;
}

.input-insert-product:focus {
    transition: 1s;
    color: #545454;
    background-color: transparent;
}

.input-insert-product::placeholder {
    color: #BDBDBD;
    font-weight: 600;
}

.button-delete-product {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C63F3F;
    background-color: #FF7171;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 25px;
    width: 60px;
    height: 50px;
}.button-delete-product:hover {
    background-color: #ff4a4a;
}

.div-categories {
    display: flex;
    justify-content: end;
    align-items: center;
}

.list-categories {
    left: 53vw;
    position: absolute;
    width: 100px;
    border-radius: 8px;
    background-color: #F6F6F6;
}

.drop-categories {
    font-size: 20px;
    position: absolute;
    border: 0px;
    background-color: transparent;
    color: #616161;
}

.category-button {
    padding: 6px 0px;
    font-size: 14px;
    color: #616161;
    font-weight: 600;
    width: 100%;
    border: 1px solid #CDCDCD;
} .category-button:hover {
    background-color: #E6E6E6;
}

.list-categories > li:first-child > .category-button {
    border-radius: 4px 4px 0px 0px;
}

.list-categories > li:last-child > .category-button {
    border-radius: 0px 0px 8px 8px;
}

#pricesTable > tbody > tr > td:first-child{
    height: 54px;
}

.div-prices-show {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end;
}

.div-prices-show > button {
    width: min-content;
    margin: 10px 0px;
}

.remove-prices-button {
    background-color: transparent;
    border: 0px;
    font-size: 20px;
    color: #616161;
}

.div-prices {
    padding: 28px 2px 0px;
    width: 100%;
}

.div-input-prices {
    display: flex;
    width: 100%;
}

.div-input-prices > div {
    width: 100%;
}

.div-input-prices > :nth-child(2) {
    margin: 0px 10px;
}

.div-input-prices > :last-child {
    margin-left: 10px;
}

.add-price-button {
    padding: 12px 20px;
    width: 100%;
    background-color: #667291;
    border: 1px solid #4f5b7d;
    color: #F0F0F0;
    font-weight: 600;
    border-radius: 8px;
    margin-top: 10px;
} .add-price-button:hover {
    background-color: #4f5b7d;
}

.ul-prices {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 166px);
    grid-column-gap: 12px;
    grid-row-gap: 10px;
    padding: 0px 10px;
    color: #545454;
    justify-content: center;
}

.list-prices {
    width: 166px;
    padding: 6px;
    border-radius: 8px;
    background-color: #ced4e6;
    color: #545454;
    border: 1px solid #7687b6;
    font-weight: 600;
}

.actions-prices {
    display: flex;
    position: absolute;
    margin-left: 115px;
    margin-top: -7px;
}

.actions-prices > button {
    background-color: transparent;
    border: 0px;
    padding: 4px;
    font-size: 13px;
    color: #545454;
} .actions-prices > button:hover {
    color: #616161;
}

.list-prices > div {
    margin-bottom: 4px;
}

.list-prices > div > h2 {
    font-size: 12px;
    color: #3c3c3c;
}

.list-prices > div > p {
    margin-top: 2px;
    font-size: 12px;
}

.toggles-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.toggles-checkbox > p {
    font-weight: 600;
    color: #747474;
}

.toggles-checkbox > input {
    height: 20px;
    width: 20px;
}

.divider {
    margin: 15px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider > p {
    color: #545454;
    font-size: 18px;
    font-weight: 600;
    margin: 0px 10px;
}

.divider > span {
    width: 100%;
    height: 1px;
    background-color: #545454;
}

.excel-dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4f5b7d;
    border-radius: 8px;
    text-align: center;
    height: 110px;
    color: #4f5b7d;
    font-weight: 600;
    padding: 10px 15px;
    cursor: pointer;
    background-color: #F0F0F0;
}

.footer-insert-products {
    display: flex;
    width: 100%;
    margin-top: 20px;
}


@media(max-width: 1000px) {
    .list-categories {left: 81%;}

    .form-insert-product {
        padding: 20px;
    }

    .content-insert-product {
        flex-direction: column-reverse;
    }
    
    .input-insert-product {margin-top: 10px;}

    .toggles {
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
    }

    .button-delete-product {
        margin-top: 10px;
        width: 100%;
    }

    .div-input:first-child {margin-top: 30px;}

    .div-input > :first-child {margin-bottom: 10px;}

    .div-input > div {flex-direction: column; margin-top: 0px;}

    .div-input > div > div {margin-top: 10px;}

    .div-input > div > :first-child {margin-right: 0px;}

    .div-input > div > :nth-child(3) {margin-left: 0px;}

    .div-input-prices {flex-direction: column;}

    .div-input-prices > :nth-child(2) {margin: 10px 0px;}

    .div-input-prices > :last-child {margin: 10px 0px;}
}

@media(max-width: 800px) {
    .list-categories {left: 72%;}
}

@media(max-width: 500px) {
    .list-categories {left: 53%;}
        
    .section-insert-product {
        padding: 10px;
    }

    .button-page-header {
        border: 2px solid #EEEEEE;
        z-index: 0px;
        margin-bottom: -8px;
        padding: 8px 14px 13px;
        font-size: 12px;
        font-weight: 600;
        background-color: #EEEEEE;
        color: #545454;
    }

}