/* sidebar */
#sidebar {
    position: fixed;
    height: 100%;
    right: 0px;
    width: 500px;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Content */
.content {
    position: absolute;
    object-fit: cover;
    height: 100%;
    right: 500px;
    width: calc(100% - 500px);
}

.content-sidebar {
    width: 70%;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 300px;
    height: 90px;
    margin-top: 20%;
    margin-left: 6%;
    margin-bottom: 70px;
}

@media(max-width: 1000px) {
    /* sidebar */
    #sidebar {
        position: relative;
        width: 100%;
    }

    .content {display: none;}

    .content-sidebar {
        width: 80%;
        font-family: 'Quicksand', sans-serif;
        display: flex;
        align-items: start;
        flex-direction: column;
    }

    .logo {
        width: 80%;
        height: 10%;
        margin-top: 20%;
        margin-left: 8%;
        margin-bottom: 70px;
    }
}