.section-setting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid #EEEEEE;
    padding: 40px;
}

.content-setting {
    width: 70%;
    margin-right: 15px;
}

.content-setting > :first-child {
    margin-bottom: 10px;
} 

.content-setting > :last-child {
    margin-top: 10px;
}

.actions-settings {
    width: 30%;
    margin-top: 22px;
} 

.actions-settings > :first-child {
    width: 100%;
}

.label-setting {
    font-size: 14px;
    font-weight: 600;
    color: #545454;
    padding: 5px;
}

.input-setting {
    padding: 14px 20px;
    width: 100%;
    border-style: solid;
    border: 2px solid #CDCDCD;
    border-radius: 10px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    transition: 1s;
}

.input-setting:focus {
    transition: 1s;
    background-color: transparent;
}

.input-setting::placeholder {
    color: #BDBDBD;
    font-weight: 600;
}

.input-description {
    border: 2px solid #CDCDCD;
    border-radius: 8px;
    color: #545454;
    padding: 10px 18px;
    font-weight: 600;
    width: 100%;
}

.info-setting {
    display: flex;
    margin-bottom: 10px;
}

.info-setting > :first-child {
    width: 100%;
}

.info-setting > :last-child {
    margin-left: 10px
}

.input-description::placeholder {
    color: #BDBDBD;
}

.footer-settings {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.setting-button-sm {
    margin-left: 0px !important;
}

.label-value {
    z-index: 2;
    padding: 10px;
    display: flex;
    border: 1px solid #A4A4A4;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 8px 0px 0px 8px;
    background-color: #CCC;
}

.border-value {
    border-radius: 0px 8px 8px 0px !important;
}

@media(max-width: 1000px) {
    .section-setting {flex-direction: column-reverse; align-items: center;}

    .section-setting > :last-child {
        margin-bottom: 20px;
        width: 100%;
    }

    .setting-button-sm {
        width: 100% !important;
        margin-top: 20px;
    }

    .actions-settings {margin-top: 0px !important;}

    .info-setting {flex-direction: column;}
    
    .info-setting > :last-child {
        margin-left: 0px;
        margin-top: 10px;
    }

    .footer-setting {flex-direction: column}

    .content-setting {width: 100%; margin-right: 0px;}
}
