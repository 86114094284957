.title-dialog {
    font-size: 26px;
    font-weight: 600;
    color: #474f66;
    margin-bottom: 20px;
}

.description-dialog {
    font-weight: 600;
    color: #646464;
    margin-bottom: 10px;
}

.delete-button-dialog {
    border: 1px solid #a33131;
    padding: 10px 15px;
    font-weight: 600;
    background-color: #df3939;
    color: #F0F0F0;
    border-radius: 6px;
} .delete-button-dialog:hover {
    background-color: #ec5a5a;
}

.cancel-button-dialog {
    border: 1px solid #474f66;
    padding: 10px 15px;
    font-weight: 600;
    background-color: #5b678b;
    color: #F0F0F0;
    border-radius: 6px;
}