.color-primary {
    color: #323A50 !important;
}

.description {
    color: #8A8A8A;
    font-weight: 800;
}

.back-button {
    padding: 10px 20px;
    width: min-content;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #707070;
    border-radius: 10px;
    font-size: 18px;
    transition: 1s;
    font-family: 'Quicksand', sans-serif;
}

.back-button:hover {
    transition: 1s;
    background-color: #505050;
    box-shadow: 1px 5px 20px rgb(152, 152, 152);
}

.forgot-password {
    display: flex;
    justify-content: end;
}

.resend-div {
    display: flex;
    width: 100px;
    background-color: red;
}

.success-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}