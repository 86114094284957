.section-discount {
    display: flex;
    width: 100%;
}

.div-discount {
    border: 2px solid #CDCDCD;
    background-color: #F0F0F0;
    border-radius: 8px;
    height: min-content;
    width: 100%;
    padding: 20px 15px;
    margin-right: 10px;
}

.div-discount-created {
    background-color: #E1E5EB;
    width: 360px;
    border: 2px solid #7188a7;
    border-radius: 8px;
    height: 360px;
}

.header-discount-created {
    display: flex;
}

.header-discount-created > button {
    background-color: #92abcc;
    width: 50%;
    padding: 8px 0px;
    border: none;
    font-weight: 600;
    border-bottom: 2px solid #7188a7;
}.header-discount-created > button:hover {
    background-color: #839dbe;
}

.header-discount-created > button:first-child {
    border-right: 1px solid #7188a7;
}

.header-discount-created > button:last-child {
    border-left: 1px solid #7188a7;
}

.form-discount > h2 {
    font-size: 20px;
    font-weight: 600;
    color: #567296;
    margin-bottom: 15px;
    margin-top: 25px;
}

.form-discount > h2:first-child {
    margin-top: 0px;
}

.div-coupon {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-column-gap: 12px;
    grid-row-gap: 10px;
    align-items: end;
    padding-left: 10px;
}

.input-discount {
    display: flex;
    flex-direction: column;
}

.input-discount > label {
    font-weight: 600;
    color: #8A8A8A;
    margin-bottom: 5px;
}

.input-discount > input {
    padding: 6px 10px;
    width: 150px;
    border-style: solid;
    color: #CDCDCD;
    border: 2px solid #CDCDCD;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    transition: 1s;
}

.input-discount > input:focus {
    transition: 1s;
    color: #545454;
    background-color: transparent;
}

.input-discount > input::placeholder {
    color: #BDBDBD;
    font-weight: 600;
}

.button-generate {
    width: 130px;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 10px;
    border: 2px solid #60b160;
    background-color: #67cf67;
    color: #2c5c2c;
    border-radius: 8px;
    margin-bottom: 2px;
}.button-generate:hover {
    background-color: #60bd60;
}

.div-card-coupon-discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 90%;
    overflow: scroll;
    overflow-x: hidden;
    padding: 10px;
}

.card-discount {
    display: flex;
    width: 312px;
    align-items: center;
    padding: 6px 10px;
    background-color: #cdd9e7;
    border-radius: 3px;
    border: 1px solid #7188a7;
    color: #545454;
    font-weight: 600;
    margin-bottom: 10px;
}

.code-coupon-discount {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.code-coupon-discount > div {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.code-coupon-discount > div:first-child {
    border-right: 1px solid #7188a7;
    margin-left: 0px;
    padding-right: 10px;
}

.code-coupon-discount > div > p {
    font-size: 12px;
    margin-bottom: 2px;
}

.code-coupon-discount > div > h2 {
    font-size: 18px;
    margin-left: -2px;
}

.dates-discount > div {
    display: flex;
    width: 165px;
}

.dates-discount > div:first-child {
    margin-bottom: 5px;
}

.dates-discount > div > p {
    font-size: 11px;
    margin-right: 5px;
}

.dates-discount > div > h2 {
    font-size: 12px;
}

.code-client-discount {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.code-client-discount > div {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.code-client-discount > div:first-child {
    border-right: 1px solid #7188a7;
    margin-left: 0px;
    padding-right: 10px;
}

.code-client-discount > div:last-child {
    border-left: 1px solid #7188a7;
    padding-left: 10px;
    align-items: start;
    margin-top: -1px;
}

.code-client-discount > div:last-child > h2 {
    font-size: 15px;
}

.code-client-discount > div > p {
    font-size: 12px;
    margin-bottom: 2px;
}

.code-client-discount > div > h2 {
    font-size: 18px;
    margin-left: -2px;
}

.discount-products-client {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.discount-products-client > div > div > p {
    font-size: 12px;
    margin-bottom: 2px;
}

.discount-products-client > div > div > h2 {
    font-size: 14px;
    width: 150px;
    height: 15px;
    overflow: hidden;
}

.box-date {
    width: 130px;
    background-color: #566d8b;
    padding: 4px 10px;
    border-radius: 6px;
}

.box-date > p {
    color: #e4e4e4;
}

.box-date > h2 {
    width: min-content;
    color: #F0F0F0;
}

.products-client {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.image-product-client {
    height: 50px;
    width: 50px;
    border-radius: 6px;
    border: 1px solid #566d8b;
    margin-right: 10px;
    object-fit: contain;
}

.box-info-client {
    display: flex;
    justify-content: space-between;
}

.products-client > div {
    display: flex;
    align-items: start;
    margin-top: 8px;    
}

.products-client > div > p {
    font-size: 12px;
    margin-bottom: 2px;
}

.products-client > div > h2 {
    margin-left: 4px;
    font-size: 12px !important;
    height: 13px;
    width: 190px;
    font-weight: bold;
    overflow: hidden;
}


@media(max-width: 1003px) {
    .section-discount { flex-direction: column;}

    .div-discount-created {width: 100%; margin-top: 10px;}

    .div-card-coupon-discount {width: 100%;}
}

@media(max-width: 470px) {
    .div-coupon {
        display: flex;
        flex-direction: column;
    }

    .input-discount { width: 100% !important; }

    .input-discount > input { width: 100% !important; }

    .card-discount { width: 220px; flex-direction: column;}

    .code-client-discount > :last-child {display: none;}

    .dates-discount { margin-top: 5px; }

    .box-info-client { flex-direction: column; }

    .box-info-client > :last-child { margin: 5px 0px 10px; }

    .products-client > div > h2 { width: 105px; }

}