/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3.2px;
  background-color: #8F8F8F;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #96bdf0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #96bdf0;
}

input:checked + .slider:before {
    background-color: #5298f4;
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 36px;
}

.slider.round:before {
  border-radius: 50%;
}