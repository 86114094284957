.section-mass-action-product {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 2px solid #EEEEEE;
    padding: 40px;
}

.section-mass-action-product > .container-table {
    margin-top: 20px;
}

.table-tr-mass-action:hover > td {
    cursor: pointer;
    background-color: #F6F6F6;
}

.table-tr-mass-action:active > td {
    background-color: #F0F0F0;
}

.group-buttons {
    display: flex;
    width: 100%;
}

.group-buttons > :first-child {
    margin-right: 10px;
}

.group-buttons > :last-child {
    margin-left: 10px;
}

.button-mass-action {
    border: 1px solid #CDCDCD;
    font-weight: 600;
    padding: 10px 15px;
    margin-top: 10px;
    background-color: #F6F6F6;
    border-radius: 8px;
    color: #545454;
} .button-mass-action:hover {
    background-color: #e2e2e2;
}

.filter-color {
    background-color: #bcbce5;
    border-color: #5e5ea7;
    color: #37375a;
} .filter-color:hover {
    background-color: #9c9ccf;
}

.save-color {
    background-color: #b1edb1;
    border-color: #4ba04b;
    color: #296529;
} .save-color:hover {
    background-color: #7dc77d;
}

.content-mass-action-product {
    width: 100%;
}

.div-input-mass-action {
    width: 100%;
}

.div-input-mass-action > :first-child {
    margin-bottom: 10px;
}

.div-input-mass-action > div {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.div-input-mass-action > div > :first-child {
    margin-right: 10px;
}

.div-input-mass-action > div > :nth-child(3) {
    margin-left: 10px;
}


@media(max-width: 840px) {

    .content-mass-action-product  {
        flex-direction: column-reverse;
    }
    
    .input-insert-product {margin-top: 10px;}

    .publish {
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
    }

    .button-delete-product {
        margin-top: 10px;
        width: 100%;
    }

    .div-input-mass-action:first-child {margin-top: 15px;}
    
    .div-input-mass-action > :first-child {margin-bottom: 0px;}

    .div-input-mass-action > div {flex-direction: column; margin-top: 0px;}

    .div-input-mass-action > div > :first-child {margin-right: 0px;}

    .div-input-mass-action > div > :nth-child(3) {margin-left: 0px;}

    .group-buttons {flex-direction: column;}
    
    .group-buttons > :first-child {margin-right: 0px;}
    
    .group-buttons > :last-child {margin-left: 0px;}
}

@media(max-width: 500px) {
        
    .section-mass-action-product {
        padding: 10px;
    }

    .button-page-header {
        border: 2px solid #EEEEEE;
        z-index: 0px;
        margin-bottom: -8px;
        padding: 8px 14px 13px;
        font-size: 12px;
        font-weight: 600;
        background-color: #EEEEEE;
        color: #545454;
    }

}