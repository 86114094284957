.mainCard {
    width: 100%;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
}

.ComponentCard {
    width: 210px;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: #eaeaea 0px 4px 12px;
    background-color: #FFFFFF;
}

.image-card {
    width: 100%;
    min-height: 150px;
    max-height: 150px;
    object-fit: contain;
}

.info-product {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px;
    margin-bottom: 0px;
    color: #545454;
}

.info-product > h1 {
    font-weight: 800;
    font-size: 15px;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.info-product > h2 {
    font-weight: 600;
    font-size: 13px;
    margin: 4px 0px 2px;
    color: #808080;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.toogle-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toogle-button > p {
    font-size: 11px;
    font-weight: 600;
    color: #545454;
}

.button-remove {
    background-color: #ffc0c0;
    color: #e96d6d;
    position: absolute;
    border: 1px solid #dc6161;
    margin-top: -1px;
    margin-right: -1px;
    border-radius: 0px 8px 0px 15px;
    padding: 5px 10px 8px;
} .button-remove:hover {
    transition: 0.5s;
    background-color: #e87b7b;
    border-color: #e28e8e;
}

.validates {
    position: absolute;
    width: 50px;
    margin-top: 6px;
    margin-left: 8px;
    display: flex;
}

.validates li {
    width: 8px;
    border: none;
    height: 8px !important;
    margin-right: 5px;
    border-radius: 90px !important;
}

.action-info {
    width: 100%;
    display: flex;
    margin-top: 6px;
    align-items: center;
    justify-content: space-between;
}

.action-info > div > h3 {
    font-size: 10px;
    font-weight: 600;
    color: #000000;
}

.action-info > div > h3 > span {
    font-weight: 800;
    color: #545454;
}

.box-info { 
    background-color: #ececec;
    color: #21214b;
    padding: 3px 8px;
    width: 85px;
    font-weight: 600    ;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.box-info > p {
    font-weight: 600;
    font-size: 11px;
}

.button-action {
    border: none;
    padding: 11px 15px;
    color: #FFFFFF;
    background-color: #42425F;
    border-radius: 5px;
} .button-action:hover { transition: 0.5s;  background-color: #55557a;}

