.section-home {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px 10px;
}

.table-pagination > h1 {
    font-weight: 600;
    font-size: 25px;
    color: #656565;
}