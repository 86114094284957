@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@350;600;700&display=swap');

/* Tables Styles */
.container-table {
    width: 100%;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 2px solid #EEEEEE;
}

.table {
    border-collapse: collapse;
}

.table th {
    color: #565656;
    padding: 20px 0px;
    font-size: 15px;
    background-color: #F6F6F6 !important;
    font-weight: 800;
}

.table th:first-child {
    padding-left: 20px;
    border-radius: 6px 0px 0px 0px;
}

.table th:last-child {
    border-radius: 0px 6px 0px 0px;
}

.table tr {
    min-width: 600px;
    border-top: 1.5px solid #EEEEEE;
}

.table tr:first-child {
    border-top: none;
}

.table th, td {
    text-align: start;
    vertical-align: middle; 
}

.table td {
    padding: 15px;
    font-size: 14px;
    color: #565656;
}

.table td > a {
    width: 100%;
    cursor: pointer;
}

/* Global Styles */

/* Buttons */
.button-info {
    border: none;
    background-color: #FFFFFF;
    width: 100%;
    height: 30px;
}

.button-save {
    border: 1px solid #21C230;
    width: 150%;
    height: 50px;
    margin-left: 10px;
    border-radius: 8px;
    background-color: #21C230;
    font-weight: 600;
    color: #FFFFFF;
    transition: 0.5s;
} .button-save:hover {background-color: #57d063;}

.button-cancel {
    border: 1px solid #E63F3F;
    height: 50px;
    border-radius: 8px;
    width: 70%;
    font-weight: 800;
    background-color: #E63F3F;
    color: #FFFFFF;
    transition: 0.5s;
} .button-cancel:hover {background-color: #ED6464;}

.button-filter {
    background-color: #F0F0F0;
    border-radius: 8px;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #7c7c7c;
    border: 1px solid #CDCDCD;
} .button-filter:hover {
    background-color: #EAEAEA;
}

.button-export {
    border: none;
    border-radius: 8px;
    background-color: #323A50;
    color: #FFFFFF;
    margin-right: 15px;
    font-weight: 600;
    padding: 0px 10px;
    width: 110px;
    height: 40px;
}.button-export:hover {
    background-color: #222838;
}.button-export:disabled {
    background-color: #484d5c;
}

.button-more {
    border: none;
    border-radius: 8px;
    background-color: #323A50;
    color: #FFFFFF;
    margin-right: 15px;
    font-weight: 600;
    padding: 0px 10px;
    width: 25%;
    height: 40px;
}.button-more:hover {
    background-color: #222838;
}.button-more:disabled {
    background-color: #484d5c;
}

.div-see-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.title-warning {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: 16vh;
    text-align: center;
    font-weight: 800;
    color: #394462;
    cursor: pointer;
}
/* Others */

.width-xs {
    min-width: 80px;
}

.width-s {
    min-width: 110px;
}

.width-md {
    min-width: 170px;
}

.width-lg {
    min-width: 210px;
}