/* sidebar sm */
#sidebar-dashboard-sm {
    position: fixed;
    height: 100%;
    width: 60px;
    padding-top: 40px;
    border-right: 0.5px solid #e0e0e0;
    box-shadow: 1px 1px 10px rgba(199, 199, 199, 0.515);
    z-index: 3;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: #323A50;
    transition: 0.5s;
}

#sidebar-container-dashboard-sm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 90%;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* content */
#content-dashboard-sm {
    position: absolute;
}

#sidebar-container-dashboard-sm > nav {
    display: flex;
    height: 100%;
    width: 40px;
    flex-direction: column;
    justify-content: center;
}

#sidebar-container-dashboard-sm > nav > ul {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#sidebar-container-dashboard-sm > nav > ul > li {
    width: 100%;
}


#sidebar-container-dashboard-sm > nav > ul > li:last-child {
    justify-content: center;
}

.menu-sm {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-sm > button {
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: none;
    padding: 15px;
    border-radius: 30px;
    background-color: transparent;
    color: #F0F0F0;
    transition: 1s;
}

.menu-sm > button:hover {
    transition: 1s;
    background-color: #4f5b7d;
}


.list-page-sm {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    border-radius: 5px;
    margin-top: 3px;
    list-style: none;
    text-decoration: none;
    width: 100%;
    border: none;
    background-color: transparent;
    transition: 0.5s;
    color: #F0F0F0 !important;
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    font-size: 18px;
    cursor: pointer;
}

.list-page-sm:hover {
    transition: 0.5s;
    background-color: #4f5b7d !important;
}


/* sidebar */
#sidebar-dashboard {
    position: fixed;
    height: 100%;
    width: 300px;
    padding-top: 40px;
    border-right: 0.5px solid #e0e0e0;
    box-shadow: 1px 1px 10px rgba(199, 199, 199, 0.515);
    z-index: 3;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: #323A50;
    transition: 0.5s;
}

#sidebar-container-dashboard {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 90%;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* content */
#content-dashboard {
    position: absolute;
}

#sidebar-container-dashboard > nav {
    display: flex;
    height: 100%;
    width: 90%;
    flex-direction: column;
    justify-content: start;
}

#sidebar-container-dashboard > nav > ul {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#sidebar-container-dashboard > nav > ul > li {
    width: 100%;
}


#sidebar-container-dashboard > nav > ul > li:last-child {
    justify-content: center;
}

.menu {
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu > button {
    display: flex;
    align-items: center;
    border: none;
    padding: 15px;
    border-radius: 30px;
    background-color: transparent;
    color: #F0F0F0;
    transition: 1s;
}

.menu > button:hover {
    transition: 1s;
    background-color: #4f5b7d;
}

.list-page {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-radius: 5px;
    margin-top: 3px;
    list-style: none;
    text-decoration: none;
    width: 100%;
    border: none;
    background-color: transparent;
    transition: 0.5s;
    color: #F0F0F0 !important;
    font-family: 'Quicksand', sans-serif;
    font-weight: 800;
    padding-left: 15px;
    font-size: 18px;
    cursor: pointer;
}

.list-page:hover {
    transition: 0.5s;
    background-color: #4f5b7d !important;
}

.list-page > .icon {
    width: 20px;
    height: 20px;
    color: #F0F0F0;
    margin-right: 10px;
}

.container-content {
    font-family: 'Quicksand', sans-serif;
    padding: 50px 35px;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.logout {
    color: #FF443E !important;
    margin-left: 0px;
    padding: 15px 0px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-list {
    width: 100%;
    border-top: 3px solid #434F72;
    border-bottom: 3px solid #434F72;
    background-color: #434F72 !important;
    position: relative;
    border-radius: 2px 20px 2px 20px;
    overflow: hidden;
    transition: 0.5s;
}


@media(max-width: 500px) {
    .container-content {align-items: start;}

    #sidebar-container-dashboard-sm {
        height: 85%;
    }

    #sidebar-container-dashboard {
        height: 80%;
    }

    #content-dashboard {
        left: 0px !important;
        width: 100% !important;
    }
}