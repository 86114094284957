.menu-order {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.menu-order > ul {
    display: flex;
}

.menu-order > ul > :first-child {
    margin-right: 8px;
}

.menu-order > ul > :nth-child(2) {
    margin-right: 8px;
}

.menu-order > ul > :nth-child(3) {
    margin-right: 8px;
}

.status {
    border-radius: 5px;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    padding: 5px 10px;
}

.status-filter {
    border: 1px solid #CDCDCD;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
}

.actions-order {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions-order > :nth-child(2) {
    margin-left: 10px;
}

.bgcolor-pending {
    background-color: #a2c6f6 !important;
    border-color: #2059a3 !important;
    color: #0d3e7e !important;
}

.bgcolor-approved {
    background-color: #a8ebae !important;
    border-color: #2fa13b !important;
    color: #177621 !important;
}

.bgcolor-refused {
    background-color: #ed9997 !important;
    border-color: #94231f !important;
    color: #791310 !important;
}

@media(max-width: 750px) {
    .menu-order {
        flex-direction: column;
        align-items: start;
    }

    .menu-order > ul {
        flex-direction: column;
        margin-bottom: 8px;
    }

    .menu-order > ul > li {
        margin-bottom: 8px;
    }

    .menu-order > div {
        flex-direction: column;
    }

    .menu-order > div > :first-child {
        margin-bottom: 8px;
    }

    .menu-order > button {
        margin-bottom: 10px;
    }
}
