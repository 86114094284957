.image-product {
    border: 1px solid #EEEEEE;
    width: 35px;
    height: 35px;
    margin-right: 12px;
}

#tbody td:nth-child(2) {
    display: flex;
    align-items: center;
}

.validates-column {
    display: flex;
}

.validates-column li {
    width: 8px;
    height: 8px !important;
    margin-right: 5px;
    border-radius: 90px !important;
}