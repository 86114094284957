.form-login {
    width: 100%;
}

.input-login {
    padding: 14px 20px;
    width: 100%;
    border-style: solid;
    border: 2px solid #CDCDCD;
    border-radius: 10px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    transition: 1s;
}

.input-login:focus {
    transition: 1s;
    background-color: transparent;
    box-shadow: 3px 3px 10px rgba(199, 199, 199, 0.757);
}

.input-login::placeholder {
    color: #BDBDBD;
    font-weight: 600;
}

.submit-button {
    padding: 15px 20px;
    width: 100%;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    background-color: #323A50;
    border-radius: 10px;
    font-size: 18px;
    transition: 1s;
    font-family: 'Quicksand', sans-serif;
} .submit-button:hover {
    transition: 1s;
    background-color: #222838;
    box-shadow: 1px 2px 20px rgb(154, 154, 154);
} .submit-button:disabled {
    background-color: #626a7f;
    box-shadow: none;
}

.forgot-password {
    display: flex;
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
    color: #8A8A8A;
    font-weight: 800;
} .forgot-password:hover {
    color: #686868;
} .forgot-password:disabled {
    color: #acacac;
}