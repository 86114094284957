.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #EEEEEE;
    font-weight: 800;
}

.not-found > h1 {
    font-size: 70px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #323A50;
    border: 1px solid #323A50;
}

.not-found > h2 {
    font-size: 50px;
    margin-bottom: 20px;
    color: #323A50;
}

.not-found > button {
    border: 2px solid #323A50;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: #EEEEEE;
    font-size: 25px;
    font-weight: 600;
    transition: 1s;
    color: #323A50;
}

.not-found > button:hover {
    transition: 1s;
    background-color: #323A50;
    color: #EEEEEE;
    transform: scale(1.05);
}