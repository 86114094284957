.graph {
    width: 100%;
    height: 50%;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1.5px solid #EEEEEE;
    padding: 18px;
}

.graph-bar {
    width: 100%;
}

.graph-bar h1 {
    font-weight: 600;
    font-size: 25px;
    color: #656565;
}

.graph-bar h2 {
    font-weight: 600;
    margin-top: 6px;
    color: #848484;
}

.graph-bar > h2 > span {
    color: #656565;
}

.graph-bar > h1 > span {
    color: #323A50;
}

.graph-bar-content {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.graph-bar-content > h3 {
    margin-left: 10px;
    color: #656565;
    font-weight: 600;
}

.graph-bar-div {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 4px;
}

.graph-bar-div-content {
    height: 20px;
    border-radius: 4px 8px 8px 4px;
    transition: 0.5s;
}