.toogle-language-button {
    background-color: #F0F0F0;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    border: 2px solid #CDCDCD;
    border-radius: 8px;
    font-weight: #545454;
} .toogle-language-button:hover {
    background-color: #F6F6F6;
}

@media(max-width: 1000px) {
    .toogle-language-button {width: 100%;}
}