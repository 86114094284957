.drop-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4f5b7d;
    border-radius: 8px;
    text-align: center;
    height: 246px;
    width: 40%;
    cursor: 'pointer';
    background-color: #F0F0F0;
}

.content-image {
    border-radius: 8px;
    width: 90%;
    height: 200px;
    object-fit: contain;
}

.drop-image > p {
    color: #4f5b7d;
    font-weight: 600;
    padding: 0px 10px;
}

@media(max-width: 1000px) {
    .drop-image {
        width: 100%;
    }
}

@media(max-width: 750px) {

    .content-image {
        max-height: 90%;
        max-width: 90%;
    }
}