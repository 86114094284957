.actions-category {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-bottom: 25px;
}

.shelflife-list {
    padding: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px; 
    align-items: center;
}

.shelflife-list > li {
    display: flex;
    align-items: center;
}

.categories-list {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 10px; 
    align-items: center;
}

.categories-list > li {
    display: flex;
    align-items: center;
}

.title-filter {
    font-size: 18px;
    font-weight: 600;
    color: #545454;
}

.actions-table {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
}

.actions-table > :last-child {
    height: 43px;
    width: 43px;
}

.switch-show {
    border: 2px solid #545454;
    padding: 9px 20px;
    border-radius: 8px;
    margin-right: 10px;
    background-color: #F6F6F6;
    font-size: 18px;
    color: #545454;
} .switch-show:hover {
    background-color: #ededed;
}

.button-category {
    border: 1px solid #1f2433;
    padding: 0px 15px;
    background-color: #4f5b7d;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #f0f0f0;
    height: 28px;
} .button-category:hover {
    transition: 0.5s;
    background-color: #394462;
}

.validate-filter {
    border: none;
    border-radius: 10px;
    height: 28px;
    width: 60px;
    color: #545454;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.validate-green {
    background-color: #00ff00;
    border-color: #37b337;
}

.validate-yellow {
    background-color: #ffff00;
    border-color: #d7d700;
}

.validate-orange {
    background-color: #ffa600;
    border-color: #d78c00;
}

.validate-red {
    background-color: #ff0000;
    border-color: #c50202;
}

.validate-green-filter {
    background-color: #92ff92;
    border: 1px solid #05c405;
} .validate-green-filter:hover {
    background-color: #44ff44;
}

.validate-yellow-filter {
    background-color: #ffff92;
    border: 1px solid #d7d700;
}.validate-yellow-filter:hover {
    background-color: #ffff18;
}

.validate-orange-filter {
    background-color: #ffcf77;
    border: 1px solid #d78c00;
}.validate-orange-filter:hover {
    background-color: #ffb429;
}

.validate-red-filter {
    background-color: #ff9e9e;
    border: 1px solid #a13636;
}.validate-red-filter:hover {
    background-color: #ff6767;
}

@media(max-width: 600px) {
    .actions-category {
        flex-direction: column;
    }

    .actions-category > :last-child {
        margin-top: 10px;
    }

    .shelflife-list {
        min-width: 0px;
        width: 100%;
    }

    .actions-table {
        flex-direction: column;
        align-items: start;
    }

    .switch-show {
        margin-bottom: 10px;
    }
}