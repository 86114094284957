.search {
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin-bottom: 40px;
}

.search > button {
    background-color: #EEEEEE;
    border: 2px solid #CDCDCD;
    padding: 15px;
    border-radius: 15px 0px 0px 15px;
    color: #A4A4A4;
    border-right: 0px;
}

.search > button:hover {
    background-color: #E1E1E1;
    border: 2px solid #CDCDCD;
    padding: 15px;
    border-right: 0px;
    border-radius: 15px 0px 0px 15px;
    color: #8A8A8A;
}

.search > button > i {
    font-size: 16px;
}

.search > input {
    border-radius: 0px 15px 15px 0px;
}